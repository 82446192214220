<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
      <b-row>
        <b-col cols="12" md="6" v-show="diller.length != 1">
          <b-form-group label="Dil">
            <v-select
              v-model="defaultDil"
              :options="diller"
              :reduce="(diller) => diller.lang"
              label="title"
              :clearable="false"
              :disabled="!form._id"
              class="invoice-filter-select d-block select-size-lg"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12 pt-3">
          <validation-provider name="Başlık" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Başlık">
              <b-form-input
                size="lg"
                class="rounded-0"
                ref="baslik"
                v-model="form.icerik[defaultDil].baslik"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Gösterim Yeri">
            <v-select
              v-model="form.gosterim_yeri"
              :options="gosterimYeri"
              :reduce="(gosterim) => gosterim.value"
              label="title"
              :clearable="false"
              class="invoice-filter-select d-block select-size-lg"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Türü">
            <v-select
              v-model="form.turu"
              :options="Turu"
              :reduce="(tur) => tur.value"
              label="title"
              :clearable="false"
              class="invoice-filter-select d-block select-size-lg"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="form.turu == 'resim'">
        <b-col cols="12" class="pt-3">
          <b-form-group label="Baglantı Adresi">
            <b-form-input
              size="lg"
              class="rounded-0"
              ref="baslik"
              v-model="form.url"
              placeholder="htts://"
              aria-describedby="input-1-live-feedback"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" class="pt-3">
          <!-- <b-form-group label="Splash Görsel">
            <b-form-file
              size="lg"
              multiple
              type="file"
              ref="file"
              class="rounded-0"
              accept="image/jpg,image/jpeg,image/png,image/gif"
              placeholder=" 480 x 480 px Görsel Yükleyiniz..."
              @change="handlerOnSumbited"
            />
          </b-form-group> -->
          <div>
            <input
              multiple
              type="file"
              ref="file"
              name="logo"
              id="selectorImagesLogoInput"
              accept="image/jpg,image/jpeg,image/png,image/gif"
              class="selector-images-input"
              @change="handlerOnSumbited"
            />
            <div class="selector-images-button" :disabled="true" @click="handlerSelectedLogoImages">
              <i class="fad fa-file-plus fa-3x" v-if="!loaded" />
              <b-spinner v-else />
            </div>
          </div>
        </b-col>
        <b-col cols="12" v-if="form.gorseller">
          <draggable
            v-model="form.gorseller"
            tag="div"
            class="row"
            handle=".draggable-task-handle"
            @end="handlerGorselSiraChange"
          >
            <b-col cols="12" md="4" lg="4" sm="6" v-for="(item, index) in form.gorseller" :key="index">
              <div class="images-show">
                <div class="images-action d-flex justify-content-between">
                  <span @click="handlerGorselRemove(item)">
                    <i class="fad fa-trash text-danger" v-b-tooltip.hover.top="'Sil'" />
                  </span>

                  <b-form-group>
                    <b-form-checkbox-group
                      :id="'check-' + index"
                      v-model="form.gorseller[index].dil"
                      :options="diller"
                      value-field="lang"
                      text-field="lang"
                    ></b-form-checkbox-group>
                  </b-form-group>
                </div>
                <b-img-lazy v-bind="mainProps" class="draggable-task-handle m-1" :src="handlerImageShow(item.gorsel)" />
              </div>
            </b-col>
          </draggable>
        </b-col>
      </b-row>
      <b-row v-else-if="form.turu == 'video'">
        <b-col cols="12" class="pt-3">
          <b-form-group label="Baglantı Adresi (Youtube)" description="Youtube video urlsini ekleyeniz">
            <b-form-input
              size="lg"
              class="rounded-0"
              ref="baslik"
              v-model="form.url"
              placeholder="htts://"
              aria-describedby="input-1-live-feedback"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="12">
          <b-form-group label="Açıklama">
            <b-form-textarea
              rows="5"
              size="lg"
              class="rounded-0"
              ref="baslik"
              v-model="form.icerik[defaultDil].aciklama"
              aria-describedby="input-1-live-feedback"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="d-flex justify-content-between align-items-center py-3">
            <label for="statu" class="h6">Kullanım Durumu</label>
            <b-form-checkbox :checked="form.statu" v-model="form.statu" switch size="lg" />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" lg="6">
          <div class="d-flex">
            <b-button squared block type="submit" size="lg" variant="primary" class="mr-2">
              <i class="fad fa-save pr-2"></i>
              <span class="align-middle">{{ form.k_no == null ? 'EKLE' : 'GÜNCELLE' }}</span>
            </b-button>
            <b-button squared size="lg" variant="danger" @click="handlerRemove(form.k_no)">
              <i class="fad fa-trash"></i>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import store from '@/store';
import Swal from 'sweetalert2';
import vSelect from 'vue-select';
import draggable from 'vuedraggable';
import { defineComponent, ref, computed, toRefs, watch } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
export default defineComponent({
  components: {
    vSelect,
    draggable,
  },
  props: {
    updateData: {
      type: Object,
      required: false,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.firmaKNo = computed(() => store.getters.getUserData.firma_k_no);
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    const { updateData } = toRefs(props);

    expo.loaded = ref(false);

    expo.mainProps = ref({
      center: true,
      fluidGrow: true,
      blank: true,
      blankColor: '#bbb',
      thumbnail: true,
      show: true,
    });

    expo.form = ref({
      k_no: null,
      icerik: {},
      gosterim_yeri: 'anasayfa',
      turu: 'resim', //resim, video,metin
      url: '',
      gorseller: [],
      statu: true,
    });

    expo.gosterimYeri = ref([
      {
        value: 'anasayfa',
        title: 'Anasayfa',
      },
      {
        value: 'kategori',
        title: 'Kategori',
      },
      {
        value: 'sayfa',
        title: 'Sayfa',
      },
    ]);
    expo.Turu = ref([
      {
        value: 'resim',
        title: 'Resim',
      },
      {
        value: 'video',
        title: 'Video',
      },
      {
        value: 'metin',
        title: 'Metin',
      },
    ]);

    expo.handlerImageShow = (val) => {
      if (val != undefined) {
        const fileName = store.getters.getUserFirma.db;
        const base_url =
          process.env.NODE_ENV === 'development'
            ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
            : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);

        const url = `${base_url}uploads/${fileName}/splash/${val}`;
        return url;
      }
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('show', true);
      // let formData = new FormData();
      // let forms = expo.form.value;
      // Object.keys(forms).map(function (key) {
      //   if (key == 'icerik') {
      //     formData.append(key, JSON.stringify(forms[key]));
      //   } else {
      //     formData.append(key, forms[key]);
      //   }
      // });
      // if (expo.file.value != null) {
      //   formData.append('splash', expo.file.value);
      // }
      store
        .dispatch('splashBildirimGuncelle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Güncelleme Başarılı', { position: 'bottom-left' });
            context.emit('show', false);
            expo.form.value = res.data.data;
            context.emit('updateRefresh', res.data.data);
          }
        })
        .catch((err) => {
          if (err.success === false) {
            toast.success(err.message, { position: 'bottom-left' });
          }
        });
    };

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('splashBildirimSil', k_no).then((res) => {
            if (res.data.success === true) {
              toast.error('Silme başarılı.', { position: 'bottom-left' });
              context.emit('show', false);
              context.emit('type', false);
            }
          });
        }
      });
    };

    expo.handlerSelectedLogoImages = () => {
      const file = document.getElementById('selectorImagesLogoInput');
      file.click();
    };

    expo.handlerOnSumbited = async () => {
      expo.loaded.value = true;
      context.emit('show', true);
      let formData = new FormData();
      let forms = context.refs.file.files;

      Object.keys(forms).map(function (key) {
        formData.append('splash', forms[key]);
      });

      formData.append('k_no', expo.form.value.k_no);

      await store
        .dispatch('splashBildirimGorselEkle', formData)
        .then(async (res) => {
          expo.loaded.value = false;
          context.emit('show', false);
          expo.form.value = res.data.data;
          // context.emit('form', res.data.data);
        })
        .catch((err) => {
          console.log(err);
          expo.loaded.value = false;
          context.emit('show', false);
        });
    };

    expo.handlerGorselRemove = (item) => {
      const data = {
        splash_k_no: expo.form.value.k_no,
        img_k_no: item.k_no,
        gorsel: item.gorsel,
      };
      store.dispatch('splashBildirimGorselSil', data).then((res) => {
        if (res.data.success == true) {
          toast.error('Görsel Silme Başarılı.', { position: 'bottom-left' });
          expo.form.value = res.data.data;
        }
      });
    };

    expo.handlerGorselSiraChange = () => {
      store
        .dispatch('splashBildirimGorselSiraGuncelle', { k_no: form.value.k_no, gorseller: expo.gorseller.value })
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Görsel sıra güncelleme başarılı.', { position: 'bottom-left' });
          }
        })
        .catch((err) => {
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              { position: 'bottom-left' }
            );
          }
        });
    };

    watch(
      updateData,
      (val) => {
        if (Object.keys(val).length) {
          expo.form.value = { ...val };
        } else {
          expo.resetForm();
        }
      },
      { immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
